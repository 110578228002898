import {
    Button,
    Col,
    Container,
    Dropdown,
    Form,
    Modal,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { ThreeDotSpinner } from "../loader";
import { dateFormate, formatWalletAddress, numberToLocaleString } from "../../config/config";
import swal from "sweetalert";
import { apiService } from "../../service/api.service";
import Pagination from "react-js-pagination";
import CopyToClipboard from "react-copy-to-clipboard";


export const PaypalOrders = () => {

    const [loader, setLoader] = useState(false);
    const [paypalOrders, setPaypalOrders] = useState([]);
    const [page, setPage] = useState(1);



    useEffect(() => {
        gasFeeSettings(page);
    }, []);


    async function gasFeeSettings(page) {
        setLoader(true);
        try {

            const response = await apiService.getPaypalOrders(page - 1);
            if (response?.status == 200) {
                setPaypalOrders(response.data);
                setLoader(false);
            }

        } catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false);
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    console.log("OK button clicked after error alert");
                });
            } else {
                setLoader(false);
                swal({
                    icon: "error",
                    text: error?.response?.data?.message
                        ? error?.response?.data?.message
                        : error?.message,
                    button: "OK",
                }).then(() => {
                    console.log("OK button clicked after error alert");
                });
            }
        }
    };


    function handlePageChange(pageNumber) {
        setPage(pageNumber);
        gasFeeSettings(pageNumber);
    }
    const setcopytext = () => {
        setTimeout(() => {
            setcopied(false);
        }, "1000");
    };
    const [copied, setcopied] = useState(false);

    const copytooltip = (
        <Tooltip id="tooltip">{copied ? "Copied" : "Copy"}</Tooltip>
    );



    return (
        <div className="dashboard-main-area">
            {loader == true ? <ThreeDotSpinner /> : ""}
            <Container fluid>
                <div className="dashboard-area-heading management-heading">
                    <Row className="row justify-content-center align-items-center mb-3">
                        <Col md={12} lg={11}>
                            <div className="heading-top-area">
                                <div className="d-flex ">
                                    <h2 className="align-self-center m-0">Paypal Orders</h2>
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row className="pb-4 justify-content-center">
                        <Col md={12} lg={11}>
                            <div className="audit-request-box">
                                <Table responsive="sm" className="UserListTable">
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>User Wallet</th>
                                            <th>Amount</th>
                                            <th>Pol Amount</th>
                                            <th>Paypal Status</th>
                                            <th>Tx Status</th>
                                            <th>Created At</th>
                                            <th>Tx Hash</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            paypalOrders &&
                                                paypalOrders?.orderList?.length > 0 ? (
                                                paypalOrders?.orderList.map((data, index) => {

                                                    const serialNumber = (page - 1) * 10 + index + 1;

                                                    return (
                                                        <tr key={index}>
                                                            <td>{serialNumber}</td>
                                                            {data?.user ?
                                                                <td onClick={setcopytext}>
                                                                    {formatWalletAddress(data?.user)}
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={copytooltip}
                                                                    >
                                                                        <CopyToClipboard
                                                                            text={data?.user}
                                                                            onCopy={() => setcopied(true)}
                                                                        >
                                                                            <i
                                                                                class="fa fa-clipboard"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </CopyToClipboard>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                : <td>N/A</td>}
                                                            <td> {data?.amount ? `$${data?.amount}` : "0"}</td>
                                                            <td> {data?.matic_amount ? Number(data?.matic_amount).toFixed(2) : "0"} POL</td>
                                                            <td>{data?.order_status ? data?.order_status : "N/A"}</td>
                                                            <td>{data?.transaction_status ? data?.transaction_status : "N/A"}</td>
                                                            <td> {data?.createdAt ? moment(data?.createdAt).format(dateFormate) : "N/A"}</td>
                                                            {data?.transaction_hash ?
                                                                <td onClick={setcopytext}>
                                                                    {formatWalletAddress(data?.transaction_hash)}
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={copytooltip}
                                                                    >
                                                                        <CopyToClipboard
                                                                            text={data?.transaction_hash}
                                                                            onCopy={() => setcopied(true)}
                                                                        >
                                                                            <i
                                                                                class="fa fa-clipboard"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </CopyToClipboard>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                : <td>N/A</td>}
                                                        </tr>
                                                    );
                                                })
                                            )
                                                : (
                                                    <tr className="no-dat-found-box">
                                                        <td colSpan={5}>
                                                            <img
                                                                src={
                                                                    require("../../assets/images/no-data.svg")
                                                                        .default
                                                                }
                                                                alt="da"
                                                            />
                                                            <h3 className="text-center text-light">
                                                                No data found
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                )}
                                    </tbody>
                                </Table>

                            </div>
                        </Col>
                    </Row>
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={10}
                        totalItemsCount={paypalOrders?.totalRecords > 10 ? paypalOrders?.totalRecords : 0}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                        prevPageText={"Prev"}
                        nextPageText={"Next"}
                    />
                </div>
            </Container>


        </div>
    );
};
